import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr, tail, initial } from 'lodash/fp';
import api from './api';

const name = 'stats';

const getStats = createAsyncThunk('stats/getStats', async () => {
  const response = await api.getStats();
  return response;
});

const getGiftCardStats = createAsyncThunk(
  'stats/getGiftCardStats',
  async ({ startDate, endDate, page, limit }) => {
    const start = startDate.toISOString().substring(0, 10);
    const end = endDate.toISOString().substring(0, 10);
    const response = await api.getGiftCardStats(start, end, page, limit);
    return response;
  }
);
const getStoreCreditStats = createAsyncThunk(
  'stats/getStoreCreditStats',
  async ({ startDate, endDate, page, limit }) => {
    const start = startDate.toISOString().substring(0, 10);
    const end = endDate.toISOString().substring(0, 10);
    const response = await api.getStoreCreditStats(start, end, page, limit);
    return response;
  }
);

const getMembershipStats = createAsyncThunk(
  'stats/getMembershipStats',
  async ({ startDate, endDate, page, limit }) => {
    const start = startDate.toISOString().substring(0, 10);
    const end = endDate.toISOString().substring(0, 10);
    const response = await api.getMembershipStats(start, end, page, limit);
    return response;
  }
);

const getMerchantStats = createAsyncThunk(
  'stats/getMerchantStats',
  async (params) => {
    const response = await api.getMerchantStats(params);
    return response;
  }
);

const getOrgsReport = createAsyncThunk('stats/getOrgsReport', async () => {
  const response = await api.getOrgsReport();
  return response;
});

const getOrgTotals = createAsyncThunk('stats/getOrgTotals', async (id) => {
  const response = await api.getOrgTotals(id);
  return response;
});

const getTransactions = createAsyncThunk(
  'stats/getTransactions',
  async (limit) => {
    const response = await api.getTransactions(limit);
    return response;
  }
);

const nextTransactions = createAsyncThunk(
  'stats/nextTransactions',
  async (payload) => {
    const { id, limit } = payload;
    const response = await api.nextTransactions(id, limit);
    return response;
  }
);

const prevTransactions = createAsyncThunk(
  'stats/prevTransactions',
  async (payload) => {
    const { id, limit } = payload;
    const response = await api.prevTransactions(id, limit);
    return response;
  }
);

const downloadCSV = createAsyncThunk('stats/downloadCSV', async () => {
  const response = await api.getTransactions(100000);
  return response;
});

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      totalsIsLoading: false,
      transactionsIsLoading: false,
      error: {},
      stats: {},
      giftCardStats: {},
      storeCreditStats: {},
      membershipStats: {},
      merchantStats: {},
      orgTotals: {},
      orgsReport: [],
      transactions: [],
      csv: [],
      noMoreNextTransactions: false,
      noMorePrevTransactions: true,
    },
    extraReducers: {
      [getStats.pending]: (state) => ({
        ...state,
        isLoading: true,
        stats: { ...state.stats },
      }),
      [getStats.fulfilled]: (state, action) => ({
        ...state,
        error: {},
        isLoading: false,
        stats: getOr({}, 'payload', action),
      }),
      [getStats.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getGiftCardStats.pending]: (state) => ({
        ...state,
        isLoading: true,
        stats: { ...state.stats },
      }),
      [getGiftCardStats.fulfilled]: (state, action) => ({
        ...state,
        error: {},
        isLoading: false,
        giftCardStats: getOr({}, 'payload', action),
      }),
      [getGiftCardStats.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getStoreCreditStats.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getStoreCreditStats.fulfilled]: (state, action) => ({
        ...state,
        error: {},
        isLoading: false,
        storeCreditStats: getOr({}, 'payload', action),
      }),
      [getStoreCreditStats.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getMembershipStats.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMembershipStats.fulfilled]: (state, action) => ({
        ...state,
        error: {},
        isLoading: false,
        membershipStats: getOr({}, 'payload', action),
      }),
      [getMembershipStats.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getMerchantStats.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getMerchantStats.fulfilled]: (state, action) => ({
        ...state,
        error: {},
        isLoading: false,
        merchantStats: getOr({}, 'payload', action),
      }),
      [getMerchantStats.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getOrgsReport.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getOrgsReport.fulfilled]: (state, action) => ({
        ...state,
        error: {},
        isLoading: false,
        orgsReport: getOr([], 'payload', action),
      }),
      [getOrgsReport.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getOrgTotals.pending]: (state) => ({
        ...state,
        totalsIsLoading: true,
      }),
      [getOrgTotals.fulfilled]: (state, action) => ({
        ...state,
        error: {},
        totalsIsLoading: false,
        orgTotals: getOr([], 'payload', action),
      }),
      [getOrgTotals.rejected]: (state, payload) => ({
        ...state,
        transactionsIsLoading: false,
        error: payload,
      }),
      [getTransactions.pending]: (state) => ({
        ...state,
        noMoreNextTransactions: false,
        noMorePrevTransactions: true,
        transactionsIsLoading: true,
      }),
      [getTransactions.fulfilled]: (state, action) => {
        const data = getOr([], 'payload', action);
        const limit = getOr(null, ['meta', 'arg'], action);
        const initialData =
          data[limit - 1] === undefined ? data : initial(data);
        return {
          ...state,
          error: {},
          noMoreNextTransactions: data[limit - 1] === undefined,
          transactionsIsLoading: false,
          transactions: initialData,
        };
      },
      [getTransactions.rejected]: (state, payload) => ({
        ...state,
        transactionsIsLoading: false,
        error: payload,
      }),

      // get transactions for CSV
      [downloadCSV.fulfilled]: (state, action) => ({
        ...state,
        csv: getOr([], 'payload', action),
      }),

      // Next Transactions pagination
      [nextTransactions.pending]: (state) => ({
        ...state,
        transactionsIsLoading: true,
        noMorePrevTransactions: false,
      }),
      [nextTransactions.fulfilled]: (state, action) => {
        const data = getOr([], 'payload', action);
        const limit = getOr(null, ['meta', 'arg', 'limit'], action);
        const initialData =
          data[limit - 1] === undefined ? data : initial(data);
        return {
          ...state,
          error: {},
          noMoreNextTransactions: data[limit - 1] === undefined,
          transactionsIsLoading: false,
          transactions: initialData,
        };
      },
      [nextTransactions.rejected]: (state, payload) => ({
        ...state,
        transactionsIsLoading: false,
        error: payload,
      }),
      // Prev Transactions pagination
      [prevTransactions.pending]: (state) => ({
        ...state,
        transactionsIsLoading: true,
        noMoreNextTransactions: false,
      }),
      [prevTransactions.fulfilled]: (state, action) => {
        const data = getOr([], 'payload', action);
        const limit = getOr(null, ['meta', 'arg', 'limit'], action);
        const tailedData = data[limit - 1] === undefined ? data : tail(data);

        return {
          ...state,
          error: {},
          noMorePrevTransactions: data[limit - 1] === undefined,
          transactionsIsLoading: false,
          transactions: tailedData,
        };
      },
      [prevTransactions.rejected]: (state, payload) => ({
        ...state,
        transactionsIsLoading: false,
        error: payload,
      }),
    },
  }),
};

const selectors = {
  selectStats: (state) => getOr({}, 'stats', state[name]),
  selectGiftCardStats: (state) => getOr({}, 'giftCardStats', state[name]),
  selectStoreCreditStats: (state) => getOr({}, 'storeCreditStats', state[name]),
  selectMembershipStats: (state) => getOr({}, 'membershipStats', state[name]),
  selectMerchantStats: (state) => getOr({}, 'merchantStats', state[name]),
  selectOrgsReport: (state) => getOr([], 'orgsReport', state[name]),
  selectOrgTotals: (state) => getOr([], 'orgTotals', state[name]),
  selectTransactions: (state) => getOr([], 'transactions', state[name]),
  selectCSV: (state) => getOr([], 'csv', state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectNoMoreNextTransactions: (state) =>
    getOr(false, 'noMoreNextTransactions', state[name]),
  selectNoMorePrevTransactions: (state) =>
    getOr(false, 'noMorePrevTransactions', state[name]),
  selectTotalsIsLoading: (state) =>
    getOr(false, 'totalsIsLoading', state[name]),
  selectTransactionsIsLoading: (state) =>
    getOr(false, 'transactionsIsLoading', state[name]),
};

export default {
  actions: {
    ...actions,
    getStats,
    getGiftCardStats,
    getStoreCreditStats,
    getMembershipStats,
    getMerchantStats,
    getOrgsReport,
    getOrgTotals,
    getTransactions,
    nextTransactions,
    prevTransactions,
    downloadCSV,
  },
  selectors,
  reducer,
  name,
};
