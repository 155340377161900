import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'enterprises';

const getEnterprisesList = createAsyncThunk(
  'enterprises/getEnterprisesList',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getEnterprisesList(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getMemberByEnterpriseId = createAsyncThunk(
  'enterprises/getMemberByEnterpriseId',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getMemberByEnterpriseId(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getMerchants = createAsyncThunk(
  'enterprises/getMerchants',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getMerchants(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getEnterpriseAssociateByEnterpriseId = createAsyncThunk(
  'enterprises/getEnterpriseAssociateByEnterpriseId',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getEnterpriseAssociateByEnterpriseId(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const setEnterpriseAssociateByEnterpriseId = createAsyncThunk(
  'enterprises/setEnterpriseAssociateByEnterpriseId',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.setEnterpriseAssociateByEnterpriseId(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const setMemberEnterprise = createAsyncThunk(
  'enterprises/setMemberEnterprise',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.setMemberEnterprise(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const setEnterprise = createAsyncThunk(
  'enterprises/setEnterprise',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.setEnterprise(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateEnterprise = createAsyncThunk(
  'enterprises/updateEnterprise',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.updateEnterprise(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateStatusEnterpriseAssociate = createAsyncThunk(
  'enterprises/updateStatusEnterpriseAssociate',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.updateStatusEnterpriseAssociate(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getPosDeviceList = createAsyncThunk(
  'enterprises/getPosDeviceList',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getPosDeviceList(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      business: {},
      enterprise: {},
      enterprisesList: {
        data: [],
        total: 0,
      },
      posDeviceList: {
        data: [],
        total: 0,
      },
    },
    reducers: {
      getBusiness: (state, action) => ({
        ...state,
        business: action.payload,
      }),
      setCurrentEnterprise: (state, action) => ({
        ...state,
        enterprise: action.payload,
      }),
    },
    extraReducers: {
      [getEnterprisesList.fulfilled]: (state, action) => {
        return {
          ...state,
          enterprisesList: action.payload,
          enterprise: action.payload?.data?.[0] || {},
        };
      },
      [getPosDeviceList.fulfilled]: (state, action) => {
        return {
          ...state,
          posDeviceList: action.payload,
        };
      },
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectCurrentEnterprise: (state) => {
    return getOr({}, 'enterprises.enterprise', state);
  },
  selectEnterprisesData: (state) => {
    return getOr(
      {
        data: [],
        total: 0,
      },
      ['enterprisesList'],
      state[name]
    );
  },
  selectPosDevices: (state) => {
    return getOr(
      {
        data: [],
        total: 0,
      },
      ['posDeviceList'],
      state[name]
    );
  },
};

export default {
  actions: {
    ...actions,
    getEnterprisesList,
    getMemberByEnterpriseId,
    getMerchants,
    getEnterpriseAssociateByEnterpriseId,
    updateStatusEnterpriseAssociate,
    setEnterpriseAssociateByEnterpriseId,
    setEnterprise,
    updateEnterprise,
    setMemberEnterprise,
    getPosDeviceList,
  },
  selectors,
  reducer,
  name,
};
