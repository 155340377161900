import api from '../../utils/apiFetch';

const path = 'gift-cards';
const merchantPath = 'merchants';

export default {
  physicalGiftCards: (payload) => api.post(`${path}/physical`, payload),
  getPhysicalGiftCardsList: (businessId) =>
    api.get(`${path}/physical-list/${businessId}`),
  getSquareGiftCardsList: ({ businessId, ...payload }) =>
    api.get(`${path}/square/${businessId}?${api.serialize(payload)}`),
  issuePhysicalGiftCard: (payload) =>
    api.post(`${path}/physical/issue`, payload),
  issueSquareGiftCard: (payload) => api.post(`${path}/issue/square`, payload),
  issueShopifyGiftCard: (payload) => api.post(`${path}/issue/shopify`, payload),
  importFromSquare: (payload) => api.post(`${path}/import/square`, payload),
  getGiftCardImages: () => api.get(`${path}/default-images`),
  deleteGiftCardImages: (payload) =>
    api.deleteRequest(`${path}/default-image/${payload}`),
  updateGiftCardImages: (payload) =>
    api.post(`${path}/default-image-status`, payload),
  createGiftCardImages: (payload) => api.post(`${path}/default-image`, payload),
  setGiftCardImage: (payload) =>
    api.file(`${merchantPath}/creativeimage`, payload),
};
