import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'business';
const getBusiness = createAsyncThunk(
  'business/getBusiness',
  async (id, { rejectWithValue }) => {
    try {
      return api.getBusiness(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const setServiceStatus = createAsyncThunk(
  'business/setServiceStatus',
  async (payload, { rejectWithValue }) => {
    try {
      return api.setServiceStatus(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getMerchantList = createAsyncThunk(
  'business/getMerchantList',
  async (params, { rejectWithValue }) => {
    try {
      return api.getMerchantList(params);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getCategoryList = createAsyncThunk(
  'business/getCategoryList',
  async (params, { rejectWithValue }) => {
    try {
      return api.getCategoryList(params);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const createMerchantCode = createAsyncThunk(
  'business/createMerchantCode',
  async (id, { rejectWithValue }) => {
    try {
      return api.createMerchantCode(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const createPricingRule = createAsyncThunk(
  'business/createPricingRule',
  async (id, { rejectWithValue }) => {
    try {
      return api.createPricingRule(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const createDiscountCode = createAsyncThunk(
  'business/createDiscountCode',
  async (id, { rejectWithValue }) => {
    try {
      return api.createDiscountCode(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getDiscountCode = createAsyncThunk(
  'business/getDiscountCode',
  async (id, { rejectWithValue }) => {
    try {
      return api.getDiscountCode(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getMerchantCode = createAsyncThunk(
  'business/getMerchantCode',
  async (params, { rejectWithValue }) => {
    try {
      return api.getMerchantCode(params);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      business: {},
    },
    reducers: {
      getBusiness: (state, action) => ({
        ...state,
        business: action.payload,
      }),
    },
    extraReducers: (builder) => {
      builder
        .addCase(getMerchantList.fulfilled, (state, action) => ({
          ...state,
          merchantList: action.payload,
        }))
        .addCase(getBusiness.fulfilled, (state, action) => ({
          ...state,
          business: action.payload,
        }))
        .addCase(setServiceStatus.fulfilled, (state, action) => {
          const { merchantList, total } = state;

          const updatedList = merchantList.data.map((r) => {
            if (r.id === action.payload.id) {
              return { ...r, ...action.payload };
            }
            return r;
          });
          return {
            ...state,
            merchantList: { total, data: updatedList },
          };
        });
    },
  }),
};

const selectors = {
  selectPhysicalGiftCard: (state) =>
    getOr([], ['physicalGiftCards'], state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectMerchantsList: (state) => getOr([], ['merchantList'], state[name]),
  selectBusinessData: (state) => getOr({}, 'business', state[name]),
};

export default {
  actions: {
    ...actions,
    getBusiness,
    setServiceStatus,
    createMerchantCode,
    getMerchantCode,
    getMerchantList,
    getCategoryList,
    createPricingRule,
    createDiscountCode,
    getDiscountCode,
  },
  selectors,
  reducer,
  name,
};
