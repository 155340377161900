import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'coupons';

const getValidateCoupon = createAsyncThunk(
  'coupons/franchise-coupons',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getValidateCoupon(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      validateCoupon: {
        data: [],
        total: 0,
      },
    },
    extraReducers: {
      [getValidateCoupon.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getValidateCoupon.fulfilled]: (state, action) => {
        return {
          ...state,
          isLoading: false,
          validateCoupon: action.payload,
        };
      },
      [getValidateCoupon.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectValidateCoupon: (state) =>
    getOr(
      {
        data: [],
        total: 0,
      },
      'validateCoupon',
      state[name]
    ),
};

export default {
  actions: {
    ...actions,
    getValidateCoupon,
  },
  selectors,
  reducer,
  name,
};
