/* eslint-disable no-prototype-builtins,no-restricted-syntax */
import api from '../../utils/apiFetch';

// const path = 'catalog';

const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export default {
  getPrimaryMembershipsList: (params) =>
    api.get(`memberships/primary?${serialize(params)}`),
  getSecondaryMembershipsList: (params) =>
    api.get(`memberships/secondary?${serialize(params)}`),
  getSharedMemberships: (params) =>
    api.get(`memberships/shared-memberships?${serialize(params)}`),
  getMembershipPlans: (id) =>
    api.get(`memberships/primaryPlans?merchantId=${id}`),
  getMemberships: (params) => api.get(`memberships/list?${serialize(params)}`),
  syncReference: (id) => api.post(`memberships/sync-reference/${id}`),
  syncCcof: (id) => api.post(`memberships/sync-ccof/${id}`),
  cancelMembership: (id) => api.post(`memberships/cancel/${id}`),
  getSubscriptions: (id) => api.get(`memberships/subscriptions/${id}`),
  syncMembers: (payload) => api.post(`memberships/sync`, payload),
  createPrimaryMerchant: (payload) => api.post(`memberships/primary`, payload),
  createSecondaryMerchant: (payload) =>
    api.post(`memberships/secondary`, payload),
  updateSecondaryMerchant: (payload) =>
    api.put(`memberships/secondary/${payload.id}`, payload),
  importMember: (payload) => api.post(`memberships/import`, payload),
};
