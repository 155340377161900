import api from '../../utils/apiFetch';

const path = 'stats';

export default {
  getStats: () => api.get(`${path}/totals`),
  getGiftCardStats: (start, end, page, limit) =>
    api.get(
      `${path}/giftcards?startDate=${start}&endDate=${end}&limit=${limit}&page=${page}`
    ),
  getStoreCreditStats: (start, end, page, limit) =>
    api.get(
      `${path}/storecredits?startDate=${start}&endDate=${end}&limit=${limit}&page=${page}`
    ),
  getMembershipStats: (start, end, page, limit) =>
    api.get(
      `${path}/memberships?startDate=${start}&endDate=${end}&limit=${limit}&page=${page}`
    ),
  getMerchantStats: (params) =>
    api.get(`${path}/merchants?${api.serialize(params)}`),
  getOrgsReport: () => api.get(`${path}/orgstats`),
  getOrgTotals: (id) => api.get(`${path}/nonprofittotals/${id}`),
  getTransactions: (limit) =>
    api.get(`${path}/admintransactions?limit=${limit}`),
  nextTransactions: (id, limit) =>
    api.get(`${path}/admintransactions?after=${id}&limit=${limit}`),
  prevTransactions: (id, limit) =>
    api.get(`${path}/admintransactions?before=${id}&limit=${limit}`),
};
