import api from '../../utils/apiFetch';

const path = 'accounts';

export default {
  login: (payload) => api.post(`${path}/authenticate`, payload),
  refreshToken: () => api.post(`${path}/refresh-token`),
  logout: () => api.post(`${path}/revoke-token`),
  forgotPassword: (payload) => api.post(`auth/forgot-password`, payload),
  resetPassword: (payload) => api.post(`auth/reset-password`, payload),
};
