/* eslint-disable no-prototype-builtins,no-restricted-syntax */
import api from '../../utils/apiFetch';

const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export default {
  getValidateCoupon: async (params) => {
    const res = await api.get(`coupons/franchise-coupons?${serialize(params)}`);
    return res;
  },
};
