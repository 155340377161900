/* eslint-disable no-prototype-builtins,no-restricted-syntax */
import api from '../../utils/apiFetch';

// const path = 'catalog';

const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export default {
  getBusiness: (id) => api.get(`merchants/${id}`),
  getMerchantList: (params) => api.get(`merchants/list?${serialize(params)}`),
  getCategoryList: (params) =>
    api.get(`merchants/categories?${serialize(params)}`),
  setServiceStatus: (payload) => api.post(`merchants/services`, payload),
  createMerchantCode: (payload) =>
    api.post(`merchants/generate-merchant-code`, payload),
  createPricingRule: (payload) =>
    api.post(`memberships/pricing-rules`, payload),
  createDiscountCode: (payload) =>
    api.post(`memberships/discount-codes`, payload),
  getDiscountCode: (params) =>
    api.get(`memberships/discount-codes?${serialize(params)}`),
  getMerchantCode: (params) => api.get(`merchants/code?${serialize(params)}`),
};
