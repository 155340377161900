/* eslint-disable no-prototype-builtins,no-restricted-syntax */
import api from '../../utils/apiFetch';

// const path = 'catalog';

const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export default {
  getMerchantList: (params) => api.get(`merchants/list?${serialize(params)}`),
  getEnterprisesList: async (params) => {
    const res = await api.get(`enterprises/list?${serialize(params)}`);
    return res;
  },
  getMemberByEnterpriseId: async (params) => {
    const res = await api.get(`enterprises/members?${serialize(params)}`);
    return res;
  },
  getMerchants: async (params) => {
    const res = await api.get(`enterprises/merchants?${serialize(params)}`);
    return res;
  },
  getEnterpriseAssociateByEnterpriseId: async (params) => {
    const res = await api.get(`enterprises/associates?${serialize(params)}`);
    return res;
  },
  setEnterpriseAssociateByEnterpriseId: async (payload) => {
    const res = await api.post(`enterprises/associates`, payload);
    return res;
  },
  updateStatusEnterpriseAssociate: async (payload) => {
    const res = await api.put(
      `enterprises/associates/status/${payload.id}`,
      payload
    );
    return res;
  },
  setMemberEnterprise: async (payload) => {
    const res = await api.post(`enterprises/members`, payload);
    return res;
  },
  setEnterprise: async (payload) => {
    const res = await api.post(`enterprises`, payload);
    return res;
  },
  updateEnterprise: async (payload) => {
    const res = await api.put(`enterprises/${payload.id}`, payload);
    return res;
  },
  getMerchantCode: (params) => api.get(`merchants/code?${serialize(params)}`),
  getPosDeviceList: (params) =>
    api.get(`merchants/pos-device-list?${serialize(params)}`),
};
