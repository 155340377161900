import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'giftCards';
const physicalGiftCards = createAsyncThunk(
  'giftCards/physicalGiftCards',
  async (payload) => {
    const response = await api.physicalGiftCards(payload).then((res) => {
      return res;
    });
    return response;
  }
);
const getPhysicalGiftCardsList = createAsyncThunk(
  'giftCards/getPhysicalGiftCardsList',
  async (payload) => {
    const response = await api.getPhysicalGiftCardsList(payload).then((res) => {
      return res;
    });
    return response;
  }
);
const getSquareGiftCardsList = createAsyncThunk(
  'giftCards/getSquareGiftCardsList',
  async (payload) => {
    const response = await api.getSquareGiftCardsList(payload).then((res) => {
      return res;
    });
    return response;
  }
);

const issuePhysicalGiftCard = createAsyncThunk(
  'giftCards/issuePhysicalGiftCard',
  async (payload, { rejectWithValue }) => {
    try {
      return api.issuePhysicalGiftCard(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const issueSquareGiftCard = createAsyncThunk(
  'giftCards/issueSquareGiftCard',
  async (payload, { rejectWithValue }) => {
    try {
      return api.issueSquareGiftCard(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const issueShopifyGiftCard = createAsyncThunk(
  'giftCards/issueShopifyGiftCard',
  async (payload, { rejectWithValue }) => {
    try {
      return api.issueShopifyGiftCard(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const importFromSquare = createAsyncThunk(
  'giftCards/importFromSquare',
  async (payload, { rejectWithValue }) => {
    try {
      return api.importFromSquare(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getGiftCardImages = createAsyncThunk(
  'gift-cards/default-images',
  async (payload, { rejectWithValue }) => {
    try {
      return api.getGiftCardImages(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const deleteGiftCardImages = createAsyncThunk(
  'giftCards/default-images',
  async (payload, { rejectWithValue }) => {
    try {
      return api.deleteGiftCardImages(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const updateGiftCardImages = createAsyncThunk(
  'giftCards/default-image-status',
  async (payload, { rejectWithValue }) => {
    try {
      return api.updateGiftCardImages(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const createGiftCardImages = createAsyncThunk(
  'giftCards/default-image',
  async (payload, { rejectWithValue }) => {
    try {
      return api.createGiftCardImages(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const setGiftCardImage = createAsyncThunk(
  'merchant/setGiftCardImage',
  async (payload) => {
    const response = await api.setGiftCardImage(payload);
    return response;
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      physicalGiftCards: [],
      physicalGiftCardList: [],
    },
    reducers: {
      physicalGiftCards: (state, action) => ({
        ...state,
        physicalGiftCards: action.payload,
      }),
    },
    extraReducers: {
      [getPhysicalGiftCardsList.fulfilled]: (state, action) => {
        return {
          ...state,
          physicalGiftCardList: action.payload,
        };
      },
    },
  }),
};

const selectors = {
  selectPhysicalGiftCard: (state) =>
    getOr([], ['physicalGiftCards'], state[name]),
  selectPhysicalGiftCardList: (state) =>
    getOr([], ['physicalGiftCardList'], state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
};

export default {
  actions: {
    ...actions,
    physicalGiftCards,
    getPhysicalGiftCardsList,
    getSquareGiftCardsList,
    issuePhysicalGiftCard,
    issueSquareGiftCard,
    issueShopifyGiftCard,
    importFromSquare,
    getGiftCardImages,
    createGiftCardImages,
    updateGiftCardImages,
    deleteGiftCardImages,
    setGiftCardImage,
  },
  selectors,
  reducer,
  name,
};
