import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'orgs';

const getOrgs = createAsyncThunk('orgs/getOrgs', async () => {
  const response = await api.getOrgs();
  return response;
});

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      orgs: [],
    },
    extraReducers: {
      [getOrgs.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getOrgs.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        orgs: getOr({}, 'payload', action),
      }),
      [getOrgs.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
    },
  }),
};

const selectors = {
  selectOrgs: (state) => getOr([], 'orgs', state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
};

export default {
  actions: {
    ...actions,
    getOrgs,
  },
  selectors,
  reducer,
  name,
};
