import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'memberships';

const getPrimaryMembershipsList = createAsyncThunk(
  'memberships/getPrimaryMembershipsList',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getPrimaryMembershipsList(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const getSecondaryMembershipsList = createAsyncThunk(
  'memberships/getSecondaryMembershipsList',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getSecondaryMembershipsList(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const getSharedMemberships = createAsyncThunk(
  'memberships/getSharedMemberships',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getSharedMemberships(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const getMembershipPlans = createAsyncThunk(
  'memberships/getMembershipPlans',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getMembershipPlans(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const getMemberships = createAsyncThunk(
  'memberships/getMemberships',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getMemberships(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const syncReference = createAsyncThunk(
  'memberships/syncReference',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.syncReference(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const syncCcof = createAsyncThunk(
  'memberships/syncCcof',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.syncCcof(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const cancelMembership = createAsyncThunk(
  'memberships/cancelMembership',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.cancelMembership(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const getSubscriptions = createAsyncThunk(
  'memberships/getSubscriptions',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getSubscriptions(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const syncMembers = createAsyncThunk(
  'memberships/syncMembers',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.syncMembers(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const createPrimaryMerchant = createAsyncThunk(
  'memberships/createPrimaryMerchant',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.createPrimaryMerchant(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const createSecondaryMerchant = createAsyncThunk(
  'memberships/createSecondaryMerchant',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.createSecondaryMerchant(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
const updateSecondaryMerchant = createAsyncThunk(
  'memberships/updateSecondaryMerchant',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.updateSecondaryMerchant(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const importMember = createAsyncThunk(
  'memberships/importMember',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.importMember(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      business: {},
      primaryList: {
        data: [],
        total: 0,
      },
      secondaryList: {
        data: [],
        total: 0,
      },
      sharedMemberships: {
        data: [],
        total: 0,
      },
    },
    reducers: {
      getBusiness: (state, action) => ({
        ...state,
        business: action.payload,
      }),
    },
    extraReducers: (builder) => {
      builder
        .addCase(getPrimaryMembershipsList.fulfilled, (state, action) => ({
          ...state,
          primaryList: action.payload,
        }))
        .addCase(getSecondaryMembershipsList.fulfilled, (state, action) => ({
          ...state,
          secondaryList: action.payload,
        }))
        .addCase(getSharedMemberships.fulfilled, (state, action) => ({
          ...state,
          sharedMemberships: action.payload,
        }))
        .addMatcher(
          (action) => action.type.endsWith('/pending'),
          (state) => ({
            ...state,
            isLoading: true,
          })
        )
        .addMatcher(
          (action) =>
            action.type.endsWith('/fulfilled') ||
            action.type.endsWith('/rejected'),
          (state) => {
            return {
              ...state,
              isLoading: false,
            };
          }
        );
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectPlans: (state) => getOr([], 'plans', state[name]),
  selectPrimaryMemberships: (state) => {
    return getOr(
      {
        data: [],
        total: 0,
      },
      ['primaryList'],
      state[name]
    );
  },
  selectSharedMemberships: (state) => {
    return getOr(
      {
        data: [],
        total: 0,
      },
      ['sharedMemberships'],
      state[name]
    );
  },
  selectSecondaryMemberships: (state) => {
    return getOr(
      {
        data: [],
        total: 0,
      },
      ['secondaryList'],
      state[name]
    );
  },
};

export default {
  actions: {
    ...actions,
    getPrimaryMembershipsList,
    getSecondaryMembershipsList,
    getSharedMemberships,
    getMembershipPlans,
    getMemberships,
    syncReference,
    syncCcof,
    cancelMembership,
    getSubscriptions,
    syncMembers,
    createPrimaryMerchant,
    createSecondaryMerchant,
    updateSecondaryMerchant,
    importMember,
  },
  selectors,
  reducer,
  name,
};
