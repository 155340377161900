/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { Button } from '@material-ui/core';

const DownloadCSV = ({ handleDownload, data, refLink, ...rest }) => {
  return (
    <>
      <Button
        onClick={handleDownload}
        color="primary"
        variant="contained"
        {...rest}
      >
        Download CSV
      </Button>
      <CSVLink
        data={data}
        filename="transactions.csv"
        className="hidden"
        ref={refLink}
        target="_blank"
      />
    </>
  );
};

DownloadCSV.propTypes = {
  handleDownload: PropTypes.func.isRequired,
  refLink: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default DownloadCSV;
